import React from 'react'
import { graphql } from 'gatsby'
import ContentPage from '~/components/Content/Page'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'

const Articles = ({ data }) => {
  const { page } = data

  return (
    <Layout>
      <Metadata title={page && page.name} />
      <ContentPage page={page && page} />
    </Layout>
  )
}

export default Articles

export const query = graphql`
  query ArticlesPage($locale: String) {
    page: contentfulPage(slug: { eq: "articles" }, node_locale: { eq: $locale }) {
      ...ContentPageFragment
    }
  }
`
